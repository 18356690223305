import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useLocation, useHistory, matchPath } from 'react-router-dom';
import IdleTimer, { useIdleTimer } from 'react-idle-timer';
import {
  Confirm,
  Tab
} from 'semantic-ui-react';

import { Nav, Alert, Banner } from '../_components';
import { Patients } from '../patients';
import { Facilities } from '../facilities';
import { Providers } from '../providers';
import { Requests } from '../requests';
import { Metrics } from '../metrics';
import { Administrators } from '../administrators';
import Login from '../session/Login';
import { Logout } from '../session/Logout';
import useToken from '../session/Token';
import { Session }  from '../session';
import { sessionService, alertService } from '../_services';
import IdleTimerModal from '../_components/IdleTimerModal';
import ConfirmEmail from '../confirmation/ConfirmEmail';
import SetPassword from '../confirmation/SetPassword';
import SelectMFA from '../confirmation/SelectMFA';
import Report from '../requests/Report';

function App() {
    const { pathname } = useLocation();
    const history = useHistory();
    const { token, setToken } = useToken();
    const [authorized, setAuth] = useState(false);
    const [routes, authorizeRoutes] = useState(<></>);
    const [defaultIndx, setDefault] = useState(0);
    const [tabIndx, setTab] = useState();
    const version = '2.03.000'


    // Idle timer states
    const timeoutMinutes = 15;
    const [timeout, setTimeout] = useState(1000 * 60 * timeoutMinutes);
    const [showModal, setShowModal] = useState(false);
    const [isTimedOut, setIsTimedOut] = useState(false);
    const [idleTimer, setIdleTimer] = useState();


    let panes = []
    let role = sessionStorage.getItem("user_role");
    let url = window.location.href;
    let segments = url.split('/');


    useEffect(()=>{
      authorizeRoutes(getRoutes);
    }, [token]);

    useEffect(()=>{
      authorizeRoutes(getRoutes);
    }, [])

    useEffect(()=>{
      authorizeRoutes(getRoutes);
    }, [pathname])

    const getRoutes = () => {
      const userRole = sessionStorage.getItem('user_role');
      if(token){
        if(userRole == 'Es_administrator' || userRole == 'Es_customer_service' || userRole == 'Es_clinical_site_manager'){
          setAuth(true);
          return authorizedRoutes;
        }
      }else{
        setAuth(false);
        return unauthorizedRoutes;
      }
      setAuth(false);
      return unauthorizedRoutes;
    }

    //Paths when the token is set and confirmed ES_ADMIN or ES_CUSTOMER_SERVICE
    const authorizedRoutes = (
      <Switch>
        <Route exact path='/' component={Patients}/>
        <Route path="/requests" component={Requests}/>
        <Route path="/patients" component={Patients} />
        <Route path="/providers" component={Providers} />
        <Route path="/facilities" component={Facilities} />
        <Route path="/metrics" component={Metrics} />
        <Route path="/administrators" component={Administrators} />
        <Route path="/requests/report/:id" component={Requests}/>
        <Route path={matchPath.path} component={Patients}/>
      </Switch>
    );

    //Paths when the auth token it not set yet
    const unauthorizedRoutes = (
      <Switch>
          <Route exact path='/' component={Login}/>
          <Route path='/ConfirmEmail/*' exact component={ConfirmEmail}/>
          <Route path='/SetPassword' exact component={SetPassword}/>
          <Route path='/SelectMFA' exact component={SelectMFA}/>
          <Route path='/request' component={Login}/>
          <Route path={matchPath.path} component={Login}/>
      </Switch>
    );

    const check = () => {
      if(segments.includes('requests')){
        return pathname;
      }
      return "/requests";
    }

    if (role == 'Es_administrator' || role == 'Es_customer_service' ) {
      if(segments.includes('requests') && defaultIndx != 3){
        setDefault(3);
      }else if(!segments.includes('requests') && defaultIndx == 3){
        setDefault(0);
      }
      panes = [
        {
          menuItem: 'Patients',
          render: () => <Redirect from="/:url*(/+)" to="/patients" component={Patients} />
        },
        {
          menuItem: 'Providers',
          render: () => <Redirect from="/:url*(/+)" to="/providers" component={Providers} />
        },
        {
          menuItem: 'Facilities',
          render: () => <Redirect from="/:url*(/+)" to="/facilities" component={Facilities} />
        },
        {
          menuItem: 'Orders',
          render: () => <Redirect from="/:url*(/+)" to={check()} component={Requests} />
        },
        {
          menuItem: 'Metrics',
          render: () =>  <Redirect from="/:url*(/+)" to="/metrics" component={Metrics} />
        },
        {
          menuItem: 'Administrators',
          render: () =>  <Redirect from="/:url*(/+)" to="/administrators" component={Administrators} />
        }
      ]
    } else {
       panes = [
        {
          menuItem: 'Patients',
          render: () => <Redirect from="/:url*(/+)" to="/patients" component={Patients} />
        },
        {
          menuItem: 'Providers',
          render: () => <Redirect from="/:url*(/+)" to="/providers" component={Providers} />
        },
        {
          menuItem: 'Sites',
          render: () => <Redirect from="/:url*(/+)" to="/facilities" component={Facilities} />
        },
        {
          menuItem: 'Orders',
          render: () => <Redirect from="/:url*(/+)" to="/requests" component={Requests} />
        }
      ]
    }


    // Idle timer functions
    const handleClose = () =>  {
      setShowModal(false);
    }
    const handleLogout = () =>  {
      setAuth(false);
      setShowModal(false);
      setIsTimedOut(false);
      sessionService
          .delete()
          .then(() => {
            history.push("/")
        }).catch(alertService.error)
    }
    const onAction = (e) => {
      setIsTimedOut(false);
    }
    const onActive = () => {
      setIsTimedOut(false);
    }
    const onIdle = () => {
      if (isTimedOut) {
        handleLogout();
      } else {
        setShowModal(true);
        idleTimer.reset();
        setIsTimedOut(true);
      }
    }
    
    if(!sessionStorage.getItem('token') && authorized == true){
      setAuth(false);
      authorizeRoutes(unauthorizedRoutes);
    }

    if(sessionStorage.getItem('token') && authorized == false){
      let registering = sessionStorage.getItem('registering');
      if(!registering){
        const userRole = sessionStorage.getItem('user_role');
        if(userRole == 'Es_administrator' || userRole == 'Es_customer_service' || 'Es_clinical_site_manager'){
          setAuth(true);
          authorizeRoutes(authorizedRoutes);
          return;
        }else{
          setAuth(false);
          authorizeRoutes(unauthorizedRoutes);
        }
      }
    }

    const handleTabChange = (event, data) => {
      setTab(data.activeIndex);
    }

    

    return (
      <div className="app-container bg-light">
        {authorized?<Nav />:<Banner/>}
        <Alert />
        {authorized?<>
          <IdleTimerModal
          showModal={showModal}
          handleClose={handleClose}
          handleLogout={handleLogout}
          timeoutInMins={timeoutMinutes}
        />
        <IdleTimer
          ref={ref => setIdleTimer(ref)}
          element={document}
          onActive={onActive}
          onIdle={onIdle}
          onAction={onAction}
          debounce={250}
          timeout={timeout} />
        </>:<></>}
        {authorized?<div className="pl-4 pt-4 pb-4 pr-4">
          <div className="tabbar">
            <Tab menu={{ secondary: true, pointing: true}} defaultActiveIndex={defaultIndx} activeIndex={tabIndx} onTabChange={handleTabChange} panes={panes}/>
          </div>
        </div>: <></>}
        <Switch>
          <Route path="/login" component={Login}/>
          <Route path="/logout" component={Session} />
          {routes}
          <Route>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
          </Route>
          
        </Switch>
      </div>
    );
}

export { App };
